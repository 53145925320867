@import url('https://fonts.googleapis.com/css2?family=Gloock&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Gloock', serif;
        background: black;
        background: linear-gradient(180deg, rgba(0,0,0,5) 0%, rgba(0,0,0,.8) 100%);
        @apply tracking-wider text-gray-200
    }
}


/**
 * Custom CSS
 *
 * Add this to your Tailwind CSS file,
 * or to a separate, custom CSS file.
 *
 * Update the background images to suit
 * your project.
 */
 .bg-header {
    background: url(./assets/img7.jpg) center center no-repeat;
    background-attachment: fixed;
  }
  
  .bg-quote {
    background: url(./assets/img7.jpg) center center no-repeat;
    background-attachment: fixed;
  }